<template>
  <div class="channel-user">
    <h5>导入记录</h5>

    <div class="mt-3">
      <b-button class="pull-right" v-b-modal.modal-1 variant="primary">导入</b-button>
      <b-table hover striped head-variant="light" :items="list" :fields="fields" class="mt-3"/>
    </div>

    <b-modal id="modal-1" title="上传用户资料" okTitle="提交" cancelTitle="取消">
      <form  class="form-inline">
        <div class="form-group mb-2 mr-3">
          <input type="file" class="form-control" accept=".xls,.xlsx" ref="inputFile" multiple="multiple" >
          <button class="btn btn-primary" @click="uploadBatch"> 导入</button>
        </div>
        <div class="form-group mb-2 mr-3">
          <span v-if="batch.userCount!=0"> 总计：用户数量：{{batch.userCount}}，彩票数量：{{batch.ticketCount}}，彩票库存：{{batch.leftCount}}</span>
        </div>
      </form>
    </b-modal>
  </div>
</template>
<script>
import User from "@/model/User"
export default {
  data() {
    return {
      batch:{
        ticketCount:0,
        userCount:0,
        leftCount:0,
      },

      fields:[
        {key:'name', label:'名称'},
        {key:'email', label:'邮箱'},
        {key:'phone', label:'电话'},
        {key:'batch', label:'批次'},
        {key:'created_at', label:'创建日期'},
      ],
      list: [],
      form:{
        file: '',
        limit: 0
      }
    }
  },
  mounted() {
  },
  methods: {
    uploadBatch() {
      let myfile = this.$refs.inputFile;
      let files = myfile.files;
      let file = files[0];
      console.log(file);
      if (file==null||file==undefined){
        this.$bvToast.toast("请选择文件！！")
        return;
      }

      let formData = new FormData();
      formData.append("file", file);
      User.upload(formData).then(response => {
        console.log(response);
        let res = response.data;
        if (res.code==0){
            this.batch=res.data;
        }else {
          this.$bvToast.toast(response.data.msg);
        }

      });
    }
  }
}
</script>
<style>
  .pull-right{
    float: right;
    margin-bottom: 10px;
  }
</style>
